import { useRouter } from 'next/router';
import { useConnectionStatus } from "@thirdweb-dev/react";
import Loading from './components/Loading';


const withProtectedRoute = (WrappedComponent) => {
	return (props) => {
		const router = useRouter();
		const connectionStatus = useConnectionStatus();

		if (connectionStatus === "unknown" || connectionStatus === "connecting") {
			return <div className='relative h-[80vh]'><Loading size={24} /></div>;
		}

		if (connectionStatus === "disconnected") {
			router.push('/signin');
			return null;
		}

		if (connectionStatus === "connected") {
			return <WrappedComponent {...props} />;
		}

		return null;
	};
};

export default withProtectedRoute;
