import Footer from "./footer";
import Wallet_modal from "./modal/wallet_modal";
import BidsModal from "./modal/bidsModal";
import BuyModal from "./modal/buyModal";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import Header02 from "./header/Header02";
import Header03 from "./header/Header03";
import Header04 from "./header/Header04";
import Profile_modal from "./modal/Profile_Modal";
import { useAuth } from "@clerk/nextjs";
import { Toaster } from "react-hot-toast";
import { useTheme } from "next-themes";
import { useDispatch } from "react-redux";

import Coming_Soon_Modal from "./modal/coming_soon_Modal";
import React, { useEffect, useState } from "react";
import { comingSoonModalShow } from "../redux/counterSlice";
import Manage_modal from "./modal/Manage_Modal";
import DashboardLayout from "./DashboardLayout";
import Buy_Domains_Modal from "./modal/Buy_Domains_Modal";
import Renewel_Modal from "./modal/Renewel_Modal";
import Netzylo_Modal from "./modal/Netzylo_modal";
import Checkout from "./modal/checkout";
import Authentication from "./modal/authentication";
import CartDrawer from "./cartDrawer";
import { useAddress } from "@thirdweb-dev/react";
import { setUser } from "../redux/authSlice";
import useProfile from "../hooks/queryHooks/getUser";
import Meta from "./Meta";

export default function Layout({ children }) {
  const route = useRouter();
  const [protectedSite, setProtectedSite] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const emailWalletAddress = useAddress();

  const dispatch = useDispatch();
  // header start
  let header;
  if (
    route.asPath === "/home/home_3" ||
    route.asPath === "/home/home_9" ||
    route.asPath === "/maintenance" ||
    route.asPath === "/home/home_12"
  ) {
    header = <Header02 />;
  } else if (route.asPath === "/platform_status") {
    header = <Header03 />;
  } else if (route.asPath === "/home/home_8") {
    header = <Header04 />;
  } else {
    header = <Header01 />;
  }

  const darkTheme = {
    border: "2px solid #02f6d1",
    background: "#101436",
    color: "#fff",
  };

  const lightTheme = {
    border: "2px solid #02f6d1",
    background: "#fff",
    color: "#101436",
  };
  const { theme } = useTheme();

  React.useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore");
    setTimeout(() => {
      if (!visitedBefore) {
        dispatch(comingSoonModalShow());
      }
    }, 15000);
  }, []);

  const getLayout = () => {
    if (route.pathname.startsWith("/dashboard")) {
      return DashboardLayout;
    } else {
      return SimpleLayout;
    }
  };

  const { data: userData } = useProfile(emailWalletAddress);

  useEffect(() => {
    if (userData) {
      console.log(userData)
      dispatch(setUser(userData));
    }
  }, [userData]);

  const Layout = getLayout();

  if (protectedSite) {
    return (
      <div class="flex items-center justify-center h-screen">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            if (password === "xrdynamics") {
              setProtectedSite(false);
            } else {
              setError("Wrong Password");
            }
          }}
          class="rounded-lg border bg-card text-card-foreground shadow-sm w-full max-w-md p-6 space-y-4"
          data-v0-t="card"
        >
          <div class="flex flex-col space-y-1.5 p-6">
            <h3 class="whitespace-nowrap tracking-tight text-2xl font-bold">
              Password Protected
            </h3>
          </div>
          <div class="p-6 space-y-4">
            <div class="space-y-2">
              <label
                class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
                for="password"
              >
                Password
              </label>
              <input
                onChange={(e) => setPassword(e.target.value)}
                class="flex h-10 rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50 w-full text-jacarta-900"
                id="password"
                placeholder="Enter your password"
                type="password"
              />
            </div>
            {error && <p className="!text-red">{error}</p>}
          </div>
          <div class="items-center p-6 flex justify-end">
            <button
              class="inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-primary text-primary-foreground hover:bg-primary/90 h-10 px-4 py-2 w-full"
              type="submit"
            >
              Enter
            </button>
          </div>
        </form>
      </div>
    );
  }

  return (
    <>
<Meta />
      <Toaster
        toastOptions={{
          duration: 2000,
          style:
            theme === "dark" || theme === "system"
              ? { ...darkTheme }
              : { ...lightTheme },
        }}
        position="top-center"
        reverseOrder={false}
      />
      <Header04 />
      <Wallet_modal />
      <Profile_modal />
      <Manage_modal />
      <BidsModal />
      <Checkout />
      <Authentication />
      <BuyModal />
      <Coming_Soon_Modal />
      <Buy_Domains_Modal />
      <Renewel_Modal />
      <Netzylo_Modal />
      <CartDrawer />
      <Layout>{children}</Layout>
      <Footer />
    </>
  );
}

function SimpleLayout({ children }) {
  return <>{children}</>;
}
