import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { authenticationHide } from "../../redux/counterSlice";
import { FaRegQuestionCircle } from "react-icons/fa";

const Authentication = () => {

  const authenticationModal = useSelector((state) => state.counter.authenticationModal);
  const dispatch = useDispatch();
  console.log(authenticationModal)
  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={authenticationModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
            <div className="modal-header">
              <h5 className="modal-title" id="renewelModalLabel">
                Two Factor Authentication
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(authenticationHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* <!-- Body --> */}
            <div className="modal-body p-6 text-center">
              <div className='text-jacarta-900  dark:text-white'>
                <h2 className='text-xl lg:text-3xl font-semibold'>Confirm Your Email</h2>
                <p className='text-xs lg:text-base mt-6 mb-1'>We will send a verification code to your email :</p>
                <p className='text-xs lg:text-sm font-bold mb-6'>pmaddren725@gmail.com</p>
                <input type="number" placeholder="Enter verification code" className="hover:ring-accent/10 focus:ring-accent rounded-md dark:bg-jacarta-700 w-full h-[3.2rem] mb-2 mt-6 " />

                <button className="inline-flex justify-center rounded-md  w-full py-4 text-sm font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent transition-transform duration-500 hover:-translate-y-1 button z-[2] "
                >Confirm</button>
                <p className="mt-8 text-sm">Confirmation emails can take upto 10 minutes to arrive</p>
                <p className="text-accent mt-2 flex justify-center items-center gap-2"><FaRegQuestionCircle/>Get Help</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Authentication;


