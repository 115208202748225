import React from 'react'
import 'react-modern-drawer/dist/index.css'
import Drawer from 'react-modern-drawer'
import { MdClose, MdWarning } from "react-icons/md";
import { FaCartShopping } from 'react-icons/fa6';
import { BsTrash } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from "react";
import { cartDrawerToggle, removeItemFromCart, changeQuantity, addToCart } from "../../redux/cartSlice";
import { useRouter } from 'next/router';
import Image from 'next/image';

import { ConnectWallet, lightTheme, useUser } from "@thirdweb-dev/react";
import { useTheme } from 'next-themes';
import { v4 as uuidv4 } from 'uuid';
import toast from 'react-hot-toast';


function CartDrawer() {

    const dispatch = useDispatch();
    const router = useRouter()
    const { isLoggedIn } = useUser()
    const [hascardAdded, setHasCardAdded] = React.useState(false)

    const [domains, setDomains] = React.useState({ slds: [], tlds: [] })


    const { cartItems, cartDrawer: isOpen } = useSelector((state) => state.cart);

    const totalPrice = useMemo(() => {
        if (cartItems.length > 0) {
            return cartItems.reduce((acc, curr) => {
                const price = curr.price ? parseFloat(curr.price) : 0;
                return acc + (curr.quantity ? curr.quantity * curr.price : price);
            }, 0);
        }
    }, [cartItems]);

    function removeDomain(item) {
        const filterDomains = cartItems.filter(domain => domain.type === 'TLD')

        const findDomain = filterDomains.filter(domain => (domain.tld === item.tld && item.type === "TLD"));

        if (findDomain.length > 0) {
            const domainsOfTLD = cartItems.filter(ct => (ct.tld === item.tld && ct.type !== "TLD"))
            if (domainsOfTLD.length > 0) {
                toast.custom((t) => (
                    <div
                        className={`${t.visible ? 'animate-enter' : 'animate-leave'
                            } max-w-md w-full bg-white dark:bg-jacarta-600 shadow-lg rounded-lg pointer-events-auto flex ring-1 ring-black ring-opacity-5`}
                    >
                        <div className="flex-1 w-0 p-4">
                            <div className="flex items-start">
                                <div className="flex-shrink-0 pt-0.5">
                                    <MdWarning className='h-10 w-10' />

                                </div>
                                <div className="ml-3 flex-1">
                                    Removing this TLD will remove {domainsOfTLD.length} relative domain
                                </div>
                            </div>
                        </div>
                        <div className="flex border-l gap-2 border-gray-200">
                            <button
                                onClick={() => {
                                    for (let i = 0; i < domainsOfTLD.length; i++) {
                                        dispatch(removeItemFromCart(domainsOfTLD[i]))
                                    }
                                    dispatch(removeItemFromCart(item))

                                    toast.dismiss(t.id)
                                }}
                                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                Yes
                            </button>
                            <button
                                onClick={() => toast.dismiss(t.id)}
                                className="w-full border border-transparent rounded-none rounded-r-lg p-4 flex items-center justify-center text-sm font-medium text-indigo-600 hover:text-indigo-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                No
                            </button>
                        </div>
                    </div>
                ))
            } else {
                dispatch(removeItemFromCart(item))

            }
            // toast.error(`Removing this TLD will remove ${domainsOfTLD.length} relative domain `)
        } else {
            dispatch(removeItemFromCart(item))

        }
    }

    function toggleDrawer() {
        dispatch(cartDrawerToggle())
    }

    async function navigateTo(path) {
        await router.push(path)
        dispatch(cartDrawerToggle())

    }
    const { theme } = useTheme();


    React.useMemo(() => {

        setHasCardAdded(cartItems.filter(ct => ct.type === "card").length > 0)
        const sldDomains = [];
        const tldDomains = [];

        cartItems.forEach(domain => {

            if (domain.type === 'TLD') {
                tldDomains.push(domain);
            } else if (domain.sld) {
                sldDomains.push(domain);
            }

        });

        setDomains({ slds: sldDomains, tlds: tldDomains })


    }, [cartItems])


    return (
        <div>

            <Drawer
                open={isOpen}
                onClose={toggleDrawer}
                direction='right'
                className='bla bla bla !w-[320px] dark:!bg-jacarta-900'
            >
                <div className='flex flex-col justify-between p-4 overflow-y-auto h-full  max-h-[100vh] '>
                    <div>
                        <div className='flex justify-between items-center'>
                            <div className='flex items-center gap-1'>
                                <FaCartShopping className='w-4 h-4' />
                                <p>Cart</p>
                            </div>
                            <MdClose onClick={toggleDrawer} className='cursor-pointer w-6 h-6 ' />
                        </div>
                        {/* <div className='bg-white shadow-lg dark:bg-jacarta-800 rounded-lg p-3 mt-6'>
                            <div className='flex items-center gap-1'>
                            
                                <p className='text-[16px] flex items-center gap-2'><Image width={32} height={32} src='/icons/matic-white.png' className='w-6 h-6 hidden dark:inline-block' /> <Image src='/icons/matic-dark.png' width={32} height={32} className='w-6 h-6 inline-block dark:hidden' /> Own your identity</p>
                            </div>
                            <p className='text-sm mt-2'>Mint Polygon domains, securely stored in a non-custodial wallet, 100% user-owned ,transferable to your discretion.
                                <Link onClick={toggleDrawer} href='/learn'><FaQuestionCircle className='ml-1 text-accent inline' /></Link>
                            </p>
                        </div> */}
                        {!hascardAdded && <div className='mt-4'> <CardsSets /> </div>}

                        {cartItems.length === 0 ?
                            <div className='flex justify-center items-center my-10 pb-2 h-full '>
                                <p className='text-sm text-center'>Empty Cart </p>
                            </div> :
                            <>
                                {domains.tlds.length > 0 && <>
                                    <div className='flex items-center justify-between border border-b-gray-300 dark:border-b-gray-200 border-t-0 border-x-0 mt-6 pb-2'>
                                        <div className='flex items-center gap-2'>
                                            <p>TLDs</p>
                                            <div className='w-3 h-3 bg-white shadow-lg dark:bg-jacarta-800 text-xs rounded-full '>{domains.tlds.length}</div>
                                        </div>
                                    </div>
                                    <div className='my-6 space-y-3 overflow-auto'>
                                        {domains.tlds.map((item) => {
                                            if (item.type === "card") {
                                                return (<CardsSets data={item} />)
                                            }
                                            return (
                                                <div className='bg-white shadow-lg dark:bg-jacarta-800 rounded-lg p-3'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <div>
                                                                {item.sld && <div class="truncate shrink whitespace-nowrap text-ellipsis font-semibold text-jacarta-900 text-sm lg:text-base dark:text-white mb-0">{item.sld}</div>}
                                                                <span class="text-jacarta-900 dark:text-white font-semibold">{item.type === 'ai' ? item.domain : (`${item.tld ? item.tld : ""}`)}</span>
                                                            </div>
                                                            <p className='text-xs'>${item.price}</p>
                                                        </div>
                                                        <BsTrash onClick={() => removeDomain(item)} className='w-4 h-4 cursor-pointer' />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </>}
                                {domains.slds.length > 0 && <>
                                    <div className='flex items-center justify-between border border-b-gray-300 dark:border-b-gray-200 border-t-0 border-x-0 mt-6 pb-2'>
                                        <div className='flex items-center gap-2'>
                                            <p>Domains</p>
                                            <div className='w-3 h-3 bg-white shadow-lg dark:bg-jacarta-800 text-xs rounded-full '>{domains.slds.length}</div>
                                        </div>
                                    </div>
                                    <div className='my-6 space-y-3 overflow-auto'>
                                        {domains.slds.map((item) => {
                                            if (item.type === "card") {
                                                return (<CardsSets data={item} />)
                                            }
                                            return (
                                                <div className='bg-white shadow-lg dark:bg-jacarta-800 rounded-lg p-3'>
                                                    <div className='flex items-center justify-between'>
                                                        <div>
                                                            <div>
                                                                {item.sld && <div class="truncate shrink whitespace-nowrap text-ellipsis font-semibold text-jacarta-900 text-sm lg:text-base dark:text-white mb-0">{item.sld}</div>}
                                                                <span class="text-jacarta-900 dark:text-white font-semibold">{item.type === 'ai' ? item.domain : (`${item.tld ? item.tld : ""}`)}</span>
                                                            </div>
                                                            <p className='text-xs'>${item.price}</p>
                                                        </div>
                                                        <BsTrash onClick={() => removeDomain(item)} className='w-4 h-4 cursor-pointer' />
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </>}
                                <div className='my-6 space-y-3 overflow-auto'>
                                    {cartItems.map((item) => {
                                        if (item.type === "card") {
                                            return (<CardsSets data={item} />)
                                        }

                                    })}

                                </div>
                            </>
                        }
                    </div>

                    <div className=' w-full'>


                        <div className='flex items-center justify-between '>
                            <p className='text-base'><span className='text-sm'>{cartItems.length}</span> Items</p>
                            <p className='text-base font-medium'>Subtotal: ${totalPrice ? parseFloat(totalPrice).toFixed(2) : 0}</p>
                        </div>
                        {isLoggedIn ?
                            <button onClick={() => navigateTo('/checkout')} className=" block items-center justify-center rounded-md text-sm lg:text-base font-medium  bg-gradient-to-tr from-hotPink via-purplish to-accent py-[18px] px-[32px] md:py-[20px] md:px-[38px] w-full mt-10 cursor-pointer button z-[2] transition-all duration-500 text-white hover:-translate-y-1 text-center">Checkout</button> :
                            <ConnectWallet
                                onConnect={() => navigateTo('/checkout')}
                                modalTitleIconUrl='https://netzylo-dev.vercel.app/logo-token.png'
                                auth={{ loginOptional: false }} modalTitle='' showThirdwebBranding={false} modalSize={"compact"} btnTitle='Continue' className=' !flex !items-center !justify-center !rounded-md text-sm !lg:text-lg !font-medium  !bg-gradient-to-tr !from-hotPink !via-purplish !to-accent !py-[18px] !px-[32px] !md:py-[20px] !md:px-[38px] !w-full !mt-10 !cursor-pointer !button z-[2] !transition-all !duration-500 !text-white !hover:-translate-y-1 !text-center'

                                theme={theme === 'dark' || theme === 'system' ? "dark" : "light"}

                            />

                        }
                    </div>
                </div>
            </Drawer >
        </div >
    )
}

export default CartDrawer


function CardsSets({ data }) {
    const dispatch = useDispatch();

    function addCardToCheckout() {
        dispatch(addToCart({
            fullname: "Tangem Card (Pack of 2)",
            price: 54.90,
            description: "",
            id: uuidv4(),
            type: 'card',
            quantity: 1, type: "card", id: "tangem-card"
        }))

    }

    return (
        <div className={`my-2 ${!data ? "gradient-box !mb-4 bg-white dark:bg-jacarta-900 p-1" : ""}`}>
            {/* <p className='text-center mb-2'>Some Text to attract users</p> */}

            <div className=' flex items-stretch justify-start gap-2 mb-4'>
                <div className='text-sm flex-grow'>
                    <div className='flex gap-2 mb-6 font-bold  text-jacarta-900 dark:text-white'>Tangem Hard Wallet
                        {/* <BsQuestionCircle /> */}
                    </div>
                    {/* <p className='mb-2'>Secure, Slim, Versatile Wallet for Storing Crypto and NFTs.</p> */}
                    <div className="bg-jacarta-500 dark:bg-jacarta-700 p-1.5 rounded-lg text-jacarta-900 dark:text-white relative text-[11px] ">
                        <label className="flex justify-between items-center ">
                            <div>
                                <p className=" font-semibold">2 Cards set / one Wallet</p>
                            </div>
                            <p className=" font-semibold">$54.90</p>
                        </label>

                    </div>
                </div>
                <div className='w-[7rem] flex items-center flex-col justify-between'>
                    <div className='flex-grow h-10'>
                        {/* <Image width={80} height={80} src='/images/tangem-cart.jpeg' className='rounded-lg'/> */}
                        <Image width={400} height={400} src='/images/tangam.png' className='rounded-lg' />
                    </div>
                    {data?.quantity > 0 ? <div className='flex flex-col items-center justify-center gap-2'>
                        <div className=''>
                            <p className='text-gray-400 text-sm'>Number of sets</p>
                        </div>
                        <div className="flex items-center justify-center gap-2 leading-tight ">
                            <button className="bg-jacarta-900 dark:bg-jacarta-600 text-white px-2 py-1 font-bold rounded-md" onClick={() => dispatch(changeQuantity({ action: "-", id: data?.id }))} >-</button>
                            <div className='bg-jacarta-900 dark:bg-jacarta-600 text-white px-2 py-1 rounded-md'>
                                {data?.quantity}
                            </div>
                            <button className="bg-jacarta-900 dark:bg-jacarta-600 text-white px-2 py-1 font-bold rounded-md"
                                onClick={() => dispatch(changeQuantity({ action: "+", id: data?.id }))}
                            >+</button>
                        </div>
                        <FaCartShopping />
                    </div> :

                        <button className="bg-jacarta-900 dark:bg-jacarta-600 text-white px-2 py-1 font-bold rounded-md" onClick={addCardToCheckout}>Add to Cart</button>}
                </div>
            </div>


        </div>
    )
}