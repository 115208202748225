import Link from "next/link";
import React from "react";
import { CgProfile } from "react-icons/cg";
import { BiPencil, BiTransfer, BiWalletAlt } from "react-icons/bi";
import { BsArrow90DegRight, BsClockHistory, BsTrash } from "react-icons/bs";
import { AiOutlineDollarCircle, AiFillLock } from "react-icons/ai";
import { IoMdSettings } from "react-icons/io"
import { FaBars } from "react-icons/fa";
import { MdDomain } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { useRouter } from "next/router";
import { useTheme } from "next-themes";
import Logo from "../public/images/logo.png";
import WhiteLogo from "../public/images/logo_white.png";
import Image from "next/image";

import { useUser } from "@thirdweb-dev/react";

const Sidebar = () => {
  const { theme } = useTheme();
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  const { user } = useUser();

  const route = useRouter();
  const routes = [
    {
      icon: CgProfile,
      name: "Profile",
      path: "/dashboard/profile",
    },
    {
      icon: MdDomain,
      name: "My Domains",
      path: "/dashboard/domain",
    },
    // {
    //   icon: BiTransfer,
    //   name: "Reverse Resolution",
    //   path: "/dashboard/resolution",
    // },
    {
      icon: BiWalletAlt,
      name: "My Wallet",
      path: "/dashboard/wallet",
    },
    // {
    //   icon: AiOutlineDollarCircle,
    //   name: "Crypto",
    //   path: "/dashboard/crypto"
    // },
    {
      icon: BsArrow90DegRight,
      name: "Transfer",
      path: "/dashboard/transfer",
    },
    {
      icon: BsClockHistory,
      name: "Transaction History",
      outer: true,
      path: `https://polygonscan.com/address/${user?.address}`,
    },
    // {
    //   icon: IoMdSettings,
    //   name: "Account Settings",
    //   path: "/dashboard/accountSetting",
    // },
    // {
    //   icon: AiOutlineDollarCircle,
    //   name: "Sell my TLD",
    //   path: "/dashboard/resel",
    // },

    // {
    //   icon: AiFillLock,
    //   name: "Change Password",
    //   path: "/dashboard/changePassword"
    // },
    // {
    //   icon: BsTrash,
    //   name: "Delete Account",
    //   path: "/dashboard/deleteAccount"
    // },
  ];

  const currentPath = routes.find(rout => rout.path === route.asPath)

  return (
    <>
      <aside className="w-full md:w-72 flex-shrink-0 flex items-center justify-between md:block bg-primary px-4 py-5 lg:py-8 mx-0 dark:bg-jacarta-900">
        <h2 className="text-xl md:text-3xl font-semibold text-left dark:text-white md:px-1 lg:px-4  w-full flex-1">
          {currentPath?.name}
        </h2>

        <div className="hidden md:flex flex-col md:px-1 lg:px-4 py-8 ">
          {routes.map((item) => {
            return (
              <Link
                key={item.path}
                href={item.path}
                target={item.outer ? "_blank" : ""}
                className={` hover:bg-accent/20 rounded-lg px-2 my-2 font-semibold text-sm lg:text-base text-start py-3 flex items-center gap-2 cursor-pointer ${item.path === route.asPath
                  ? "bg-jacarta-800 dark:bg-gradient-to-tr dark:from-hotPink dark:via-purplish dark:to-accent text-white"
                  : ""
                  }`}
              >
                <item.icon className="w-5 h-5" /> {item.name}
              </Link>
            );
          })}
        </div>

        <div className="md:hidden flex justify-end  ">
          <button onClick={toggleDrawer} className="">
            <FaBars className="w-6 h-6 text-jacarta-900 dark:text-white" />
          </button>
          <Drawer
            style={
              theme === "dark"
                ? { background: "#07091b" }
                : { background: "white" }
            }
            open={isOpen}
            onClose={toggleDrawer}
            direction="left"
            className="bla bla bla px-4 py-8"
          >
            <Link className="shrink-0" href="/">
              <div className="dark:hidden">
                <Image
                  src={Logo}
                  height={28}
                  width={130}
                  alt="Xhibiter | NFT Marketplace"
                  className=" h-auto "
                />
              </div>
              <div className="hidden dark:block">
                <Image
                  src={WhiteLogo}
                  height={28}
                  width={130}
                  alt="Xhibiter | NFT Marketplace"
                />
              </div>
            </Link>
            {routes.map((item) => {
              return (
                <Link
                  onClick={toggleDrawer}
                  key={item.path}
                  href={item.path}
                  className={`text-jacarta-800 dark:text-white hover:bg-accent/20 rounded-lg px-2 my-2 font-semibold text-sm lg:text-base text-start py-3 flex items-center gap-2 cursor-pointer ${item.path === route.asPath
                    ? "bg-gradient-to-tr from-hotPink via-purplish to-accent text-white"
                    : ""
                    }`}
                >
                  <item.icon className="w-5 h-5" /> {item.name}
                </Link>
              );
            })}
          </Drawer>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
