import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { checkoutHide } from "../../redux/counterSlice";
import { BiWalletAlt } from 'react-icons/bi';
import { FaParking } from 'react-icons/fa'
const Checkout = () => {

  const checkoutModal = useSelector((state) => state.counter.checkoutModal);
  const dispatch = useDispatch();


  const [answer, setAnswer] = React.useState('yes');

  const handleRadioChange = (e) => {
    setAnswer(e.target.value);
  };
  return (
    <div className="">
      {/* <!-- Wallet Modal --> */}
      <div
        className={checkoutModal ? "block modal fade show p-4 fixed z-[999999]  " : "modal fade hidden"}
      >
        <div className="modal-dialog max-w-lg w-full gradient-box ">
          <div className="modal-content   dark:bg-jacarta-900  overflow-auto m-0  rounded-md opacity-100 right-0">
            <div className="modal-header">
              <h5 className="modal-title" id="renewelModalLabel">
                Choose Protection Level
              </h5>
              <button
                type="button"
                className="btn-close"
                onClick={() => dispatch(checkoutHide())}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  className="fill-jacarta-900 h-6 w-6 dark:fill-white"
                >
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                </svg>
              </button>
            </div>
            {/* <!-- Body --> */}
            <div className="modal-body p-6 text-center">
              <div className='text-jacarta-900  dark:text-white'>
                <label htmlFor="selfCustody">
                  <div className='cursor-pointer mt-4 border border-gray-300 dark:border-gray-200 rounded-lg p-4'>
                    <p className="text-sm font-medium dark:text-white text-jacarta-900 text-center mb-2">Self Custody</p>
                    <div className='flex justify-between items-center gap-4'>
                      <BiWalletAlt className="text-accent flex-none  w-6 h-6" />
                      <label className='flex justify-between items-center gap-2 md:gap-4'>
                        <p className='text-sm dark:text-white text-jacarta-900'>Claim into your own wallet. No protection included</p>
                        <input
                          type="radio"
                          id="selfCustody"
                          value="yes"
                          checked={answer === 'yes'}
                          onChange={handleRadioChange}
                          className="text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent/20 dark:ring-offset-accent/80 focus:ring-2 dark:bg-accent/60 dark:border-gray-600"
                        />
                      </label>
                    </div>
                  </div>
                </label>
                <label htmlFor="parking">
                  <div className='cursor-pointer mt-4 border border-gray-300 dark:border-gray-200 rounded-lg p-4'>
                    <p className="text-sm font-medium dark:text-white text-jacarta-900 text-center mb-2"> Netzylo parking</p>
                    <div className='flex justify-between items-center  gap-4'>
                      <FaParking className="text-accent  flex-none  w-6 h-6" />
                      <label className='flex justify-between items-center gap-2 md:gap-4'>
                        <p className='text-sm dark:text-white text-jacarta-900'>$4/domain/year <span>or</span> $10/domain/year for $100+ domains. <span>Domains are stored in a custodial wallet designed to protect against theft.</span></p>
                        <input
                          type="radio"
                          id="parking"
                          value="no"
                          checked={answer === 'no'}
                          onChange={handleRadioChange}
                          className=" text-accent bg-gray-100 border-gray-300 focus:ring-accent dark:focus:ring-accent/20 dark:ring-offset-accent/80 focus:ring-2 dark:bg-accent/60 dark:border-gray-600 "
                        />
                      </label>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >

  );
};

export default Checkout;


