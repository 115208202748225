import "../styles/globals.css";
import { ThemeProvider, useTheme } from "next-themes";
import PublicLayout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { MetaMaskProvider } from "metamask-react";
import UserContext from "../components/UserContext";
import React, { useEffect, useRef, useState } from "react";
import config from "react-reveal/globals";

import client from "../AppoloClient";
import { ApolloProvider } from "@apollo/client";
import "react-modern-drawer/dist/index.css";
import "swiper/css";
import "swiper/css/navigation";

import { QueryClient, QueryClientProvider } from 'react-query';
import { Hydrate } from 'react-query/hydration';
import ThirdWebCustomProvider from "../ThirdWebCustomProvider";
import { PagesProgressBar as ProgressBar } from 'next-nprogress-bar';


const queryClient = new QueryClient();
function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const scrollRef = useRef({
    scrollPos: 0,
  });
  config({ ssrFadeout: true });

  return (
    <QueryClientProvider client={queryClient}>

      <Hydrate state={pageProps.dehydratedState}>
        <Provider store={store}>
          <ThirdWebCustomProvider>
            <ThemeProvider enableSystem={true} attribute="class">

              <MetaMaskProvider>
                <UserContext.Provider value={{ scrollRef: scrollRef }}>
                  <ApolloProvider client={client}>
                    {/* {contentLoaded ? */}
                    <PublicLayout>
                      <Component {...pageProps} />
                      <ProgressBar
                        height="3px"
                        color="#02f6d1"
                        options={{ showSpinner: false }}
                        shallowRouting
                      />
                    </PublicLayout>
                    {/* :
                    <PreLoader />
                  } */}
                  </ApolloProvider>
                </UserContext.Provider>
              </MetaMaskProvider>

            </ThemeProvider>
          </ThirdWebCustomProvider>
        </Provider>
      </Hydrate>

    </QueryClientProvider>
  );
}




export default MyApp;


