import React from 'react';
import Sidebar from './Sidebar';

import withProtectedRoute from '../withProtectedRoute';

const DashboardLayout = ({ children }) => {
  return (
    <div className=" relative flex flex-col md:flex-row gap-2 mt-32 ">
      <Sidebar />
      <div className="flex-grow h-full dark:bg-jacarta-900">
        <main className="px-4 md:px-8">{children}</main>
      </div>
    </div>
  );
};

export default withProtectedRoute(DashboardLayout);

