import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  cartItems: [],
  buyDomainsModal: false,
  cartDrawer: false,
  netzyloCard: null,
  netzyloWallet: false,
  stripeCheckoutModal: false,
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    netzyloCardToggle: (state) => {
      state.netzyloCard = !state.netzyloCard;
    },

    netzyloWalletToggle: (state, action) => {
      state.netzyloWallet = action.payload;
    },

    cartDrawerToggle: (state) => {
      state.cartDrawer = !state.cartDrawer;
    },

    addToCart: (state, action) => {
      const { payload } = action;
      console.log("Adding to cart: ", payload);
      const findItem = state.cartItems.find((item) => item.id === payload.id);
      if (!findItem) {
        state.cartItems = [...state.cartItems, payload];
      } else {
        if (findItem.type === "card") {
          state.cartItems = state.cartItems.map((item) => {
            if (item.id === findItem.id) {
              return {
                ...item,
                quantity: findItem.quantity + payload.quantity,
              };
            }
            return item;
          });
        }
      }
    },
    removeItemFromCart: (state, action) => {
      const { payload } = action;
      const findItem = state.cartItems.find((item) => item.id === payload.id);
      if (findItem) {
        state.cartItems = state.cartItems.filter(
          (item) => item.id !== payload.id
        );
      }
    },
    changeQuantity: (state, action) => {
      const { payload } = action;
      const findItem = state.cartItems.find((item) => item.id === payload.id);
      if (findItem) {
        console.log(findItem.quantity < 2)
        if (findItem.quantity === 1 && payload.action === "-") {
          state.cartItems = state.cartItems.filter(
            (item) => item.id !== payload.id
          );
        } else {
          state.cartItems = state.cartItems.map((item) => {
            if (payload.action === "+") {
              return { ...item, quantity: item.quantity + 1 };
            } else if (payload.action === "-") {
              return { ...item, quantity: item.quantity - 1 };
            }
          });
        }
      }
    },
    buyDomainsModalShow: (state) => {
      state.buyDomainsModal = true;
    },
    buyDomainsModalhide: (state) => {
      state.buyDomainsModal = false;
    },
    stripeCheckoutModalShow: (state) => {
      state.stripeCheckoutModal = true;
    },
    stripeCheckoutModalhide: (state) => {
      state.stripeCheckoutModal = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addToCart,
  removeItemFromCart,
  buyDomainsModalShow,
  buyDomainsModalhide,
  cartDrawerToggle,
  netzyloCardToggle,
  netzyloWalletToggle,
  stripeCheckoutModalShow,
  stripeCheckoutModalhide,
  changeQuantity,
} = cartSlice.actions;

export default cartSlice.reducer;
