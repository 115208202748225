import React, { useMemo } from "react";

import {
	ThirdwebProvider,
	embeddedWallet,
} from "@thirdweb-dev/react";

import { useSelector } from "react-redux";
import { useRouter } from "next/router";

function ThirdWebCustomProvider({ children }) {
	const { cartDrawer: isOpen } = useSelector((state) => state.cart);
	const router = useRouter();


	const wallets = useMemo(() => {
		// if (router.asPath === "/wallet") {
		// 	return [
		// 		metamaskWallet(),
		// 		coinbaseWallet(),
		// 		walletConnect(),
		// 		trustWallet(),
		// 		zerionWallet(),
		// 		bloctoWallet(),
		// 		frameWallet(),
		// 		rainbowWallet(),
		// 		phantomWallet(),
		// 	]
		// }
		// else if (!isOpen) {
		// 	return [
		// 		embeddedWallet(),
		// 		metamaskWallet(),
		// 		coinbaseWallet(),
		// 		walletConnect(),
		// 		trustWallet(),
		// 		zerionWallet(),
		// 		bloctoWallet(),
		// 		frameWallet(),
		// 		rainbowWallet(),
		// 		phantomWallet(),
		// 	]
		// } else {
		return [embeddedWallet({
			auth: {
				options: ["email", "google", "apple", "facebook"]
			}
		})]
		// }
	}, [isOpen, router.asPath])

	return (

		<ThirdwebProvider
			activeChain={process.env.NEXT_PUBLIC_ACTIVE_CHAIN}
			clientId={process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID}
			authConfig={{
				domain: 'https://netzylo-dev.vercel.app',
				authUrl: "/api/auth/thirdweb"
			}}

			supportedWallets={wallets}
		>
			{children}

		</ThirdwebProvider>

	);
}




export default ThirdWebCustomProvider;
