import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import cartReducer from './cartSlice';
import  authReducer  from './authSlice';


export const store = configureStore({
	reducer: {
		counter: counterReducer,
		cart: cartReducer,
		auth:authReducer
	},
});
